import '../styles/global.scss';
import '../styles/pages/quiz.scss';

import {HeadFC} from "gatsby";

import React, {useEffect, useState} from 'react'
import QuizQuestion from '../components/QuizQuestion';
import QuizFooter from '../components/QuizFooter';

import {
    BigLogoImage
} from "../images";
import useAnalytics from "../hooks/useAnalytics";

type Props = {
    id: number
}

const QuizPage = ({id}: Props) => {
    const {initAnalytics, saveEmail, saveSubType} = useAnalytics();

    useEffect(() => {
        initAnalytics()
    }, []);

    const [questions, setQuestions] = useState([
        {
            id: 1,
            name: "Do you consider yourself productive?",
            answers: ['Yes', 'No', 'It’s difficult to say'],
        },
        {
            id: 2,
            name: "Do you set tasks for your day/week/year?",
            answers: ['Yes', 'No', 'Rarely'],
        },
        {
            id: 3,
            name: "Do you track a progress of the task/goal?",
            answers: ['Yes', 'No', 'Rarely'],
        },
        {
            id: 4,
            name: "Do you do self-education?",
            answers: ['Yes, permanently', 'Not enough', 'Rarely'],
        },
        {
            id: 5,
            name: "Do you keep records of income and expenses?",
            answers: ['Yes', 'No', 'Start but give up'],
        },
        {
            id: 6,
            name: "In what areas do you want to develop skills?",
            answers: ['Professional', 'Creative', 'Productivity', 'Psychological'],
        },
        {
            id: 7,
            name: "What device do you prefer to study with?",
            answers: ['A computer', 'Tablet', 'Rarely', 'Telephone'],
        },
        {
            id: 8,
            name: "How many hours a day are you ready to pay to self-development?",
            answers: ['Up to an hour a day', 'More than 2 hours a day', 'Intervals of 5-10 minutes in free time'],
        },
        {
            id: 9,
            name: "Do you write down your thoughts and ideas in some kind of diary or notes?",
            answers: ['Yes', 'No', 'No, but I would like to'],
        },
        {
            id: 10,
            name: "Would you like to combine all the tools in one mobile application?",
            answers: ['Yes', 'Need to take a look'],
        },
    ])

    return (
        <>
            <div className="quizBox">
                <div className="quizWrap">
                    <div className="quizMain">
                        <div className="quizLogo">
                            <img src={BigLogoImage} alt="" />
                        </div>
                        {questions.map(key => {
                            if(key.id == id){
                                return <QuizQuestion
                                            id={key.id}
                                            question={key.name}
                                            answers={key.answers}
                                            arrLength={questions.length}
                                            lastLink='/trial' />
                            } else{
                                return null;
                            }

                        })}
                    </div>
                    <QuizFooter />
                </div>
            </div>
        </>
    )
}

export default QuizPage;

export const Head: HeadFC = () => (
    <>
        <title>Quiz - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
